import Nina from '@nina-protocol/js-sdk'
import initSdk from '@/lib/utils/initSdk'
import { PaginationProps, PublicKeyString } from '../types'

export const fetchAccountCollected = async (
  accountPublicKey: PublicKeyString,
  pagination: PaginationProps = { limit: 100000, offset: 0, sort: 'desc' },
) => {
  await initSdk()

  const accountCollectedReponse = await Nina.Account.fetchCollected(
    accountPublicKey,
    pagination,
  )

  return accountCollectedReponse
}
