import { CoinflowPurchaseProtection } from '@coinflowlabs/react'
import * as anchor from '@coral-xyz/anchor'
import { SolanaExtension } from '@magic-ext/solana'
import { InstanceWithExtensions, SDKBase } from '@magic-sdk/provider'
import { WalletReadyState } from '@solana/wallet-adapter-base'
import {
  WalletContextState,
  useConnection,
  useWallet,
} from '@solana/wallet-adapter-react'
import { Connection, Transaction } from '@solana/web3.js'
import axios from 'axios'
import { Magic } from 'magic-sdk'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { encodeBase64 } from 'tweetnacl-util'
import { fetchAccountCollected } from '@/lib/account/fetchAccountCollected'
import { fetchAccountFollowing } from '@/lib/account/fetchAccountFollowing'
import { fetchAccountVerifications } from '@/lib/account/fetchAccountVerifications'
import { fetchFavoritesForAccount } from '@/lib/favorite/fetchFavoritesForAccount'
import { fetchHubsForAccount } from '@/lib/hub/fetchHubsForAccount'
import {
  AccountVerification,
  Favorite,
  Following,
  Hub,
  Profile,
  PublicKeyString,
  Release,
} from '@/lib/types'
import { logEvent } from '@/lib/utils/event'

type MagicInstanceWithSolanaExtension = InstanceWithExtensions<
  SDKBase,
  { solana: SolanaExtension }
>

export interface WalletContextValues {
  connection?: Connection
  email: string
  wallet: WalletContextState
  walletExtension: WalletContextState
  connectMagicWallet: (magic: MagicInstanceWithSolanaExtension) => void
  setupMagic: () => Promise<MagicInstanceWithSolanaExtension>
  pendingTransactionMessage: string | undefined
  shortPendingTransactionMessage: string | undefined
  initialLoginFlag?: boolean
  setInitialLoginFlag?: React.Dispatch<React.SetStateAction<boolean>>
  sessionSignature: {
    message: string
    signature: string
    publicKey: string
  } | null
  collection: { [key: string]: number } | undefined
  userHasRelease: (publicKey: string) => boolean
  fetchCollected: () => void
  fetchUserSubscriptions: () => void
  favorites: PublicKeyString[]
  userSubscriptions: string[] | undefined
  userHubs: Hub[]
  fetchFavorites: () => void
  userHasFavoritedItem: (publicKey: PublicKeyString) => boolean
  connectedAccountVerifications: AccountVerification[]
  handleFetchAccountVerifications?: () => void
  reconnecting: boolean
  profile: Profile | null
}

const WalletContext = createContext<WalletContextValues>({
  connection: undefined,
  email: '',
  wallet: {} as WalletContextState,
  walletExtension: {} as WalletContextState,
  connectMagicWallet: () => {},
  setupMagic: () => Promise.resolve({} as MagicInstanceWithSolanaExtension),
  pendingTransactionMessage: undefined,
  shortPendingTransactionMessage: undefined,
  initialLoginFlag: false,
  setInitialLoginFlag: () => {},
  sessionSignature: null,
  collection: {},
  userHasRelease: () => false,
  fetchCollected: () => {},
  favorites: [],
  userSubscriptions: undefined,
  fetchUserSubscriptions: () => {},
  userHubs: [],
  fetchFavorites: () => {},
  userHasFavoritedItem: () => false,
  connectedAccountVerifications: [],
  handleFetchAccountVerifications: () => {},
  reconnecting: false,
  profile: null,
})

const WalletContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { connection } = useConnection()
  const walletExtension = useWallet()

  const [magicWallet, setMagicWallet] = useState<WalletContextState | null>(
    null,
  )

  const [magic, setMagic] = useState<
    MagicInstanceWithSolanaExtension | undefined
  >(undefined)

  const [pendingTransactionMessage, setPendingTransactionMessage] =
    useState<string>()

  const [shortPendingTransactionMessage, setShortPendingTransactionMessage] =
    useState<string>()

  const [email, setEmail] = useState<string>('')

  const [sessionSignature, setSessionSignature] = useState<{
    message: string
    signature: string
    publicKey: string
  } | null>(null)

  const [connectedAccountVerifications, setConnectedAccountVerifications] =
    useState<AccountVerification[]>([])

  const [collection, setCollection] = useState<{ [key: string]: number }>()
  const [favorites, setFavorites] = useState<PublicKeyString[]>([])
  const [userHubs, setUserHubs] = useState<Hub[]>([])
  const [reconnecting, setReconnecting] = useState<boolean>(false)
  const [initialLoginFlag, setInitialLoginFlag] = useState<boolean>(false)

  const [userSubscriptions, setUserSubscriptions] = useState<[] | undefined>(
    undefined,
  )

  const [profile, setProfile] = useState<Profile | null>(null)

  const cleanUpAfterLogout = () => {
    setSessionSignature(null)
    localStorage.removeItem('lastReleaseCatalogNumber')
    localStorage.removeItem('lastHubUpload')
    localStorage.removeItem('sessionSignature')
    setReconnecting(false)
    setProfile(null)
  }

  const { connectMagicWallet, setupMagic } = walletContextHelper({
    setMagicWallet,
    magic,
    setMagic,
    setEmail,
    cleanUpAfterLogout,
  })

  const wallet = useMemo(() => {
    if (magicWallet) {
      return magicWallet
    }

    return walletExtension
  }, [walletExtension, magicWallet])

  useEffect(() => {
    const sessionSignatureLocalStorage =
      localStorage.getItem('sessionSignature')

    if (sessionSignatureLocalStorage) {
      setReconnecting(true)
    }

    const isMagicWallet = localStorage.getItem('nina_magic_wallet') === 'true'

    if (isMagicWallet) {
      checkIfMagicWalletIsLoggedIn()
    }
  }, [])

  const checkIfMagicWalletIsLoggedIn = async () => {
    const _magic = new Magic(process.env.MAGIC_KEY!, {
      extensions: {
        solana: new SolanaExtension({
          rpcUrl: process.env.SOLANA_RPC_CLUSTER!,
        }),
      },
    }) as MagicInstanceWithSolanaExtension

    setMagic(_magic)
    connectMagicWallet(_magic)
  }

  useEffect(() => {
    const transactionMessage = () => {
      if (wallet?.wallet?.adapter.name === 'Nina') {
        setPendingTransactionMessage('Completing transaction')
        setShortPendingTransactionMessage('Completing...')
      } else {
        setPendingTransactionMessage('Please approve transaction in wallet')
        setShortPendingTransactionMessage('Approve in wallet')
      }
    }

    transactionMessage()
  }, [wallet])

  useEffect(() => {
    let sessionSignatureObject: {
      message: string
      signature: string
      publicKey: string
    }

    const sessionSignatureLocalStorage =
      localStorage.getItem('sessionSignature')

    if (wallet.publicKey) {
      logEvent('wallet_connected', 'engagement', wallet)

      if (sessionSignatureLocalStorage) {
        setReconnecting(true)

        const { publicKey, message, signature } = JSON.parse(
          sessionSignatureLocalStorage,
        )

        sessionSignatureObject = {
          publicKey,
          message,
          signature,
        }
        setSessionSignature(sessionSignatureObject)
      }

      const signSession = async () => {
        const shouldSign =
          sessionSignature || sessionSignatureObject ? false : true

        if (shouldSign) {
          try {
            const message = new TextEncoder().encode(
              wallet!.publicKey!.toBase58(),
            )

            const messageBase64 = encodeBase64(message)
            const signature = await wallet!.signMessage!(message)
            const signatureBase64 = encodeBase64(signature)
            sessionSignatureObject = {
              message: messageBase64,
              signature: signatureBase64,
              publicKey: wallet!.publicKey!.toBase58(),
            }

            localStorage.setItem(
              'sessionSignature',
              JSON.stringify(sessionSignatureObject),
            )
            setSessionSignature(sessionSignatureObject)
            setReconnecting(false)
            await axios.post(`${process.env.NINA_ID_ENDPOINT}/login`, {
              publicKey: wallet!.publicKey!.toBase58(),
            })
          } catch (error) {
            await wallet.disconnect()
            cleanUpAfterLogout()
          }
        }
      }

      signSession()

      fetchProfile()
      fetchCollected()
      fetchUserSubscriptions()
      fetchFavorites()
      handleFetchAccountVerifications()
      fetchUserHubs()
    } else if (!sessionSignatureLocalStorage) {
      cleanUpAfterLogout()
    }
  }, [wallet.publicKey])

  const handleFetchAccountVerifications = async () => {
    if (wallet.publicKey) {
      const { verifications } = await fetchAccountVerifications(
        wallet.publicKey?.toBase58() as string,
      )

      setConnectedAccountVerifications(verifications)
    } else {
      setConnectedAccountVerifications([])
    }
  }

  const fetchCollected = async () => {
    const { collected } = await fetchAccountCollected(
      wallet.publicKey?.toBase58() as string,
    )

    const collectedPublicKeys = {} as { [key: string]: number }
    collected.forEach((release: Release) => {
      collectedPublicKeys[release.publicKey] = 1
    })

    setCollection(collectedPublicKeys)
  }

  const fetchProfile = async () => {
    const response = await axios.get(
      `${process.env.NINA_ID_ENDPOINT}/profile/${wallet.publicKey?.toBase58()}`,
    )

    setProfile(response.data.profile)
  }

  const fetchUserSubscriptions = async () => {
    const subscriptionData = await fetchAccountFollowing(
      wallet.publicKey?.toBase58() as string,
      {
        limit: 100000,
        offset: 0,
      },
    )

    const mappedSubscriptions = subscriptionData.following.map(
      (item: Following) => {
        if (item.account) {
          return item.account.publicKey
        }

        if (item.hub) {
          return item.hub.publicKey
        }

        return null
      },
    )

    setUserSubscriptions(mappedSubscriptions)
  }

  const fetchFavorites = async () => {
    const favoritesData = await fetchFavoritesForAccount({
      publicKey: wallet.publicKey?.toBase58() as string,
      pagination: {
        limit: 100000,
        offset: 0,
      },
    })

    setFavorites(
      favoritesData.favorites.map((favorite: Favorite) => favorite.publicKey),
    )
  }

  const fetchUserHubs = async () => {
    const hubsData = await fetchHubsForAccount({
      publicKey: wallet.publicKey?.toBase58() as string,
      pagination: {
        limit: 50,
        offset: 0,
      },
      withAccountData: false,
    })

    // if (!hubsData) retur

    setUserHubs(hubsData.hubs)
  }

  const userHasFavoritedItem = (publicKey: PublicKeyString) => {
    if (favorites && favorites.includes(publicKey)) {
      return true
    } else {
      return false
    }
  }

  const userHasRelease = (publicKey: PublicKeyString) => {
    if (collection && collection[`${publicKey}`] > 0) {
      return true
    } else {
      return false
    }
  }

  return (
    <WalletContext.Provider
      value={{
        connection,
        email,
        wallet,
        walletExtension,
        connectMagicWallet,
        setupMagic,
        pendingTransactionMessage,
        shortPendingTransactionMessage,
        initialLoginFlag,
        setInitialLoginFlag,
        sessionSignature,
        userHasRelease,
        fetchCollected,
        collection,
        favorites,
        userSubscriptions,
        fetchUserSubscriptions,
        userHubs,
        fetchFavorites,
        userHasFavoritedItem,
        connectedAccountVerifications,
        handleFetchAccountVerifications,
        reconnecting,
        profile,
      }}
    >
      <CoinflowPurchaseProtection
        merchantId="nina"
        coinflowEnv={
          process.env.SOLANA_RPC_CLUSTER_ENV === 'devnet' ? 'staging' : 'prod'
        }
      />

      {children}
    </WalletContext.Provider>
  )
}

const walletContextHelper = ({
  setMagicWallet,
  magic,
  setMagic,
  setEmail,
  cleanUpAfterLogout,
}: {
  setMagicWallet: React.Dispatch<
    React.SetStateAction<WalletContextState | null>
  >
  magic: MagicInstanceWithSolanaExtension | undefined
  setMagic: React.Dispatch<
    React.SetStateAction<MagicInstanceWithSolanaExtension | undefined>
  >
  setEmail: React.Dispatch<React.SetStateAction<string>>
  cleanUpAfterLogout: () => void
}) => {
  const setupMagic = async () => {
    let _magic = magic

    if (!_magic) {
      _magic = new Magic(process.env.MAGIC_KEY!, {
        extensions: {
          solana: new SolanaExtension({
            rpcUrl: process.env.SOLANA_RPC_CLUSTER!,
          }),
        },
      }) as MagicInstanceWithSolanaExtension
      setMagic(_magic)
    }

    return _magic
  }

  const connectMagicWallet = async (
    magic: MagicInstanceWithSolanaExtension,
  ) => {
    const isLoggedIn = await magic.user.isLoggedIn()

    if (isLoggedIn) {
      const user = await magic.user.getInfo()

      if (user) {
        try {
          await axios.post(`${process.env.NINA_ID_ENDPOINT}/login`, {
            issuer: user.issuer,
            publicKey: user.publicAddress,
          })
        } catch (error) {
          console.warn('error', error)
        }
        setEmail(user.email || '')

        const wallet = {
          connected: true,
          connecting: false,
          disconnecting: false,
          disconnect: async () => {
            await magic.user.logout()
            setMagicWallet(null)
          },
          publicKey: new anchor.web3.PublicKey(user.publicAddress as string),
          signMessage: async (message: string) => {
            return await magic.solana.signMessage(message)
          },
          signTransaction: async (
            transaction: Transaction | anchor.web3.VersionedTransaction,
          ) => {
            if (transaction instanceof anchor.web3.VersionedTransaction) {
              const message = transaction.message.serialize()
              const signedMessage = await magic.solana.signMessage(message)
              transaction.addSignature(
                new anchor.web3.PublicKey(user.publicAddress as string),
                signedMessage,
              )

              return transaction
            } else {
              const serializeConfig = {
                requireAllSignatures: false,
                verifySignatures: true,
              }

              const signedTransaction = await magic.solana.signTransaction(
                transaction,
                serializeConfig,
              )

              const deserializedTransaction = Transaction.from(
                signedTransaction.rawTransaction,
              )

              let didSignMore = false
              transaction.signatures.forEach((signature) => {
                if (signature.signature) {
                  deserializedTransaction.addSignature(
                    signature.publicKey,
                    signature.signature,
                  )
                  didSignMore = true
                }
              })

              if (didSignMore) {
                signedTransaction.rawTransaction =
                  deserializedTransaction.serialize()
                signedTransaction.signature = deserializedTransaction.signatures
              }

              const deserializedSignedTransaction = Transaction.from(
                signedTransaction.rawTransaction,
              )

              return deserializedSignedTransaction
            }
          },
          sendTransaction: async (
            transaction: Transaction,
            connection: Connection,
          ) => {
            if (transaction instanceof anchor.web3.VersionedTransaction) {
              const message = transaction.message.serialize()
              const signedMessage = await magic.solana.signMessage(message)
              transaction.addSignature(
                new anchor.web3.PublicKey(user.publicAddress as string),
                signedMessage,
              )

              return transaction
            } else {
              const serializeConfig = {
                requireAllSignatures: false,
                verifySignatures: true,
              }

              const signedTransaction = await magic.solana.signTransaction(
                transaction,
                serializeConfig,
              )

              if (transaction.signatures.length > 1) {
                const deserializedTransaction = anchor.web3.Transaction.from(
                  signedTransaction.rawTransaction,
                )

                transaction.signatures.forEach((signature) => {
                  if (signature.signature) {
                    deserializedTransaction.addSignature(
                      signature.publicKey,
                      signature.signature,
                    )
                  }
                })
                signedTransaction.rawTransaction =
                  deserializedTransaction.serialize()
              }

              const txid = await connection.sendRawTransaction(
                signedTransaction.rawTransaction,
              )

              return txid
            }
          },
          wallet: {
            adapter: {
              name: 'Nina',
              url: 'https://ninaprotocol.com',
              icon: 'https://ninaprotocol.com/favicon.ico',
            },
            readyState: 'connected' as WalletReadyState,
            publicKey: new anchor.web3.PublicKey(user.publicAddress as string),
            connected: true,
            connecting: false,
            disconnecting: false,
          },
          wallets: [],
          supportedTransactionVersions: ['legacy', 0],
          autoConnect: true,
          select: () => {},
          connect: async () => {},
          signAllTransactions: async () => {},
          signIn: async () => {},
        } as unknown as WalletContextState

        setMagicWallet(wallet)
      }
    } else {
      cleanUpAfterLogout()
    }
  }

  return {
    connectMagicWallet,
    setupMagic,
  }
}

export default {
  Context: WalletContext,
  Provider: WalletContextProvider,
}
