import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { PublicKeyString } from '../types'

export const fetchAccountVerifications = cache(
  async (profilePublicKey: PublicKeyString) => {
    await initSdk()

    const accountResponse = await Nina.Account.fetchVerifications(
      profilePublicKey,
    )

    return accountResponse
  },
)
