import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { ContentNodeType, Hub, PaginationProps } from '../types'

export const fetchHubsForAccount = cache(
  async ({
    publicKey,
    pagination = { limit: 5, offset: 0, sort: 'desc' } as PaginationProps,
    withAccountData = true,
  }: {
    publicKey: string
    pagination: PaginationProps
    withAccountData: boolean
  }) => {
    await initSdk()

    const accountResponse = await Nina.Account.fetchHubs(
      publicKey,
      pagination,
      withAccountData,
    )

    if (accountResponse?.hubs.length > 0) {
      accountResponse.hubs = accountResponse?.hubs.map((hub: Hub) => {
        hub.type = ContentNodeType.Hub

        return hub
      })
    }

    return accountResponse
  },
)
