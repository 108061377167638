import Nina from '@nina-protocol/js-sdk'
import { WalletContextState } from '@solana/wallet-adapter-react'

const initSdk = async (wallet?: WalletContextState) => {
  try {
    if (!process.env.NINA_API_ENDPOINT) {
      throw new Error('NINA_API_ENDPOINT is not defined')
    }

    if (!process.env.SOLANA_RPC_CLUSTER) {
      throw new Error('SOLANA_RPC_CLUSTER is not defined')
    }

    if (!process.env.SOLANA_RPC_CLUSTER_ENV) {
      throw new Error('SOLANA_RPC_CLUSTER_ENV is not defined')
    }

    if (!process.env.NINA_PROGRAM_ID) {
      throw new Error('NINA_PROGRAM_ID is not defined')
    }

    let uid

    if (typeof window !== 'undefined') {
      uid = checkIfHasNinaId()
    }

    if (
      !Nina.program ||
      (wallet && Nina.provider.wallet !== wallet) ||
      (typeof window !== 'undefined' && !Nina.uid)
    ) {
      await Nina.init({
        endpoint: process.env.NINA_API_ENDPOINT,
        rpcEndpoint: process.env.SOLANA_RPC_CLUSTER,
        cluster: process.env.SOLANA_RPC_CLUSTER_ENV,
        programId: process.env.NINA_PROGRAM_ID,
        apiKey: process.env.NINA_API_KEY,
        wallet,
        isNode: false,
        uid,
      })
    }
  } catch (error) {
    console.error(error)
  }
}

// We want to have a persistent identifier that we can send on all requests to handle API ratelimiting
const checkIfHasNinaId = () => {
  try {
    if (localStorage) {
      let ninaId = localStorage.getItem('ninaId')

      if (!ninaId) {
        ninaId = crypto.randomUUID()
        localStorage.setItem('ninaId', ninaId)
      }

      return ninaId
    }
  } catch (error) {
    console.warn(error)

    return undefined
  }
}

export default initSdk
