import { Favorite, PaginationProps, PublicKeyString } from '../types'
import initSdk from '../utils/initSdk'

export const fetchFavoritesForAccount = async ({
  publicKey,
  pagination,
}: {
  publicKey: PublicKeyString
  pagination?: PaginationProps
}) => {
  await initSdk()

  const favoritesResponse = await fetch(
    `${
      process.env.NINA_ID_ENDPOINT
    }/favorites/account?publicKey=${publicKey}&limit=${
      pagination?.limit || 20
    }&offset=${pagination?.offset || 0}`,
  )

  interface FavoriteResponse extends Favorite {
    type: 'post' | 'release'
  }

  const data = (await favoritesResponse.json()) as {
    success: boolean
    favorites: FavoriteResponse[]
    total: number
  }

  const favorites: Favorite[] = []
  data.favorites.forEach((favorite) => {
    if (favorite.post) {
      favorite = {
        ...favorite,
        ...favorite.post,
        type: 'post',
      }
      favorites.push(favorite)
    } else if (favorite.release) {
      favorite = {
        ...favorite,
        ...favorite.release,
        type: 'release',
      }
      favorites.push(favorite)
    }
  })

  return {
    favorites,
    total: data.total,
  }
}
